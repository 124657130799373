// buscar post-function e implementar ergo y resto

const version="admin 2.0.5";
const page_vars=getUrlVars();
let page_lng="es";
/*
const server_url= location.hostname === "localhost" 
? "http://localhost/farmaval/admin_functions.php"
: "https://www.farmaval.net/app/admin_functions.php";
*/
const server_base= location.hostname === "localhost" 
	  ? "http://localhost/farma/server/"
	  : "https://www.farmaval.net/server/";
const server_url= server_base+"admin_functions.php";

function get_msg(msg)
{
	const e=document.querySelector("[aria-msg="+msg+"_"+page_lng+"]");
	if(e)
		return(e.innerHTML);
	
	return("");
}
function show_lng(lng)
{
	document.querySelectorAll(".lng").forEach(element=>{
		element.style.display = 'none';
	});
	document.querySelectorAll(".lng_"+lng).forEach(element=>{
		element.style.display = 'inline-block';
	});	
	page_lng=lng;
	localStorage["wircos_lng"]=lng;
}
	
function lng_click(e)
{
	var lng=this.getAttribute("href");
	show_lng(lng);
	e.preventDefault();
}	

function getUrlVars() 
{
	var vars = {};
	var parts = window.location.href.replace(/[?&#]+([^=&#]+)=([^&#]*)/gi, function(m,key,value) 
	{
		vars[key] = value;
	});
	return(vars);
}

// ************************************************************
// Farmaval functions
// ************************************************************

function fill_table(to,data,subject,click_function=null)
{
	const table=document.getElementById(to);
	const field_id=table.getAttribute("aria-id");
	const body=table.querySelector("tbody");
	const header=table.querySelector("thead");

	header.innerHTML="";
	body.innerHTML=""

	const from=document.querySelector("#elements ."+table.getAttribute("aria-row"));
	for(let a=0;a<=data.length;a++)
	{
		let row=from.cloneNode(true);
		if(a>0)
		{
			row.setAttribute("aria-id",data[a-1][field_id]);
			row.setAttribute("id",subject+"_"+data[a-1][field_id]);
			row.setAttribute("aria-data",JSON.stringify(data[a-1]));
		}
		for(const child of row.children) 
		{
			if(a==0){
				child.innerHTML=child.getAttribute("aria-label");
			}else{
				if(data[a-1][child.getAttribute("aria-field")])
				{
					if(child.classList.contains("select"))
					{
						child.querySelector(".select_"+data[a-1][child.getAttribute("aria-field")]).classList.remove("hide");
					}else
						child.innerHTML=data[a-1][child.getAttribute("aria-field")];
				}
			}
		}
		if(a==0)
			header.append(row);
		else
			body.append(row);
	}
	if(click_function)
	{
		table.classList.add("table_hover");
		body.querySelectorAll(".tr").forEach(element=>{
			element.addEventListener('click', function(e)
			{
				e.preventDefault();
				click_function(element.getAttribute("aria-id"));
			});
		})
	}
}

async function load_list(params,to,count,click_function=null)
{
	params["session_token"]=page_vars["session_token"];
	const res = await fetch(server_url, 
		{
			method: 'post',
			mode: "cors",
			headers: {
				  'Accept': 'application/json',
				  'Content-Type': 'application/json'
			},
			body: JSON.stringify(params)
		});	
	let json=await res.json();
	if(!json){ alert("error"); return;}
	if(!json.status){alert("error: "+json.error); return};

	//console.log(json.data);

	document.getElementById(count).innerText=json.data.length;

	const table=document.getElementById(to);
	const field_id=table.getAttribute("aria-id");
	const body=table.querySelector("tbody");
	const header=table.querySelector("thead");
	body.innerHTML="";
	header.innerHTML="";
	const from=document.querySelector("#elements ."+table.getAttribute("aria-row"));
	for(let a=0;a<=json.data.length;a++)
	{
		let row=from.cloneNode(true);
		if(a>0)
		{
			row.setAttribute("aria-id",json.data[a-1][field_id]);
			row.setAttribute("id","pharm_"+json.data[a-1][field_id]);
		}
		for(const child of row.children) 
		{
			if(a==0){
				child.innerHTML=child.getAttribute("aria-label");
			}else{
				if(json.data[a-1][child.getAttribute("aria-field")])
				{
					if(child.classList.contains("select"))
					{
						child.querySelector(".select_"+json.data[a-1][child.getAttribute("aria-field")]).classList.remove("hide");
					}else
						child.innerHTML=json.data[a-1][child.getAttribute("aria-field")];
				}
			}
		}
		if(a==0)
			header.append(row);
		else
			body.append(row);
	}
	if(click_function)
	{
		table.classList.add("table_hover");
		body.querySelectorAll(".tr").forEach(element=>{
			element.addEventListener('click', function(e)
			{
				e.preventDefault();
				click_function(element.getAttribute("aria-id"));
			});
		})
	}

}

// load configuration data
async function load_config()
{
	console.log(server_url);
	const res = await fetch(server_url, 
		{
			method: 'post',
			mode: "cors",
			headers: {
				  'Accept': 'application/json',
				  'Content-Type': 'application/json'
			},
			body: JSON.stringify({function:"loadConfigData",session_token:page_vars["session_token"]})
		});	
	console.log("Res: ",res);
	let json=await res.json();
	if(!json){ alert("error"); return;}
	if(!json.status){alert("error: "+json.error); return};

	if(json.ergo)
		options_to_ergo(json.ergo);

	if(json.ergometrix)
	{
		fill_table("table_ergometrix",json.ergometrix,"ergometrix",function(data)
		{
			fill_info_from_table("section_ergometrix","ergometrix_field","ergometrix_"+data,data);
		});
	}
	console.log("Products: ",json.products);
	if(json.products)
		{
			fill_table("table_products",json.products,"prod",function(data)
			{
				fill_info_from_table("section_products","prod_field","prod_"+data,data);
			});
	}
	if(json.actions)
		actions_to_table(json.actions);

	return;

	// info data
	const fields=document.querySelectorAll("."+field_class);
	fields.forEach((field)=>
	{
		if(field.classList.contains("checklist"))
			value_to_checklist(field,null);
		else
			field.value="";

		if(json.data[field.getAttribute("id")])
		{
			if(field.classList.contains("checklist"))
				value_to_checklist(field,json.data[field.getAttribute("id")]);
			else
				field.value=json.data[field.getAttribute("id")];
		}
	});
}

// load and show all the pharm data
async function load_data(params,field_class)
{
	params["session_token"]=page_vars["session_token"];
	const res = await fetch(server_url, 
		{
			method: 'post',
			mode: "cors",
			headers: {
				  'Accept': 'application/json',
				  'Content-Type': 'application/json'
			},
			body: JSON.stringify(params)
		});	
	let json=await res.json();
	if(!json){ alert("error"); return;}
	if(!json.status){alert("error: "+json.error); return};

	console.log("pharm data:",json);

	// info data
	const fields=document.querySelectorAll("."+field_class);
	fields.forEach((field)=>
	{
		if(field.classList.contains("checklist"))
			value_to_checklist(field,null);
		else
			field.value="";

		if(json.data[field.getAttribute("id")])
		{
			if(field.classList.contains("checklist"))
				value_to_checklist(field,json.data[field.getAttribute("id")]);
			else
				field.value=json.data[field.getAttribute("id")];
		}
	});
	
	const ags=document.querySelectorAll(".ag_field");
	ags.forEach((field)=>
	{
		if(field.classList.contains("checklist"))
			value_to_checklist(field,null);
		else{
			if(field.tagName==="IMG")
				field.setAttribute("src",field.getAttribute("aria-default"));
			else
				field.value="";
		}
		if(json.agreement)
		{
			//alert("muestra agreement");
			if(json.agreement[field.getAttribute("id")])
			{
				if(field.tagName==="IMG")
				{
					field.setAttribute("src",json.agreement[field.getAttribute("id")]);
				}
				else
					field.value=json.agreement[field.getAttribute("id")];
			}
		}
	});
	
	document.getElementById("ag_pharm").value=document.getElementById("pharm_id").value;

	// services
	fill_table("table_ps",json.services,"ps",function(data)
	{
		fill_info_from_table("tab_pharm_services","ps_field","ps_"+data,data);
	});

	// staff
	fill_table("table_staff",json.staff,"staff",function(data)
	{
		fill_info_from_table("tab_pharm_staff","staff_field","staff_"+data,data);
	});

	// actions
	fill_table("table_actions",json.actions,"action",function(data)
	{
		fill_info_from_table("tab_pharm_actions","action_field","action_"+data,data);
	});
	
	//ergo
	fill_table("table_ergo",json.ergo,"ergo",function(data)
	{
		fill_info_from_table("tab_pharm_ergo","ergo_field","ergo_"+data,data);
	});

	if(json.data.group)
		document.getElementById("btn_view_agreement").setAttribute("aria-group",json.data.group);
	else
		document.getElementById("btn_view_agreement").removeAttribute("aria-group");

} 

function fill_info_from_table(parent,field_class,row_id,id)
{
	if(parent)
	{
		if(document.getElementById(parent).querySelector(".selected"))
			document.getElementById(parent).querySelector(".selected").classList.remove("selected");
		document.getElementById(parent).querySelector("[aria-id='"+id+"']").classList.add("selected");
	}

	document.getElementById(parent).setAttribute("aria-row",id);
	const row=document.getElementById(row_id);
	const data=JSON.parse(row.getAttribute("aria-data"));
	const fields=document.querySelectorAll("."+field_class);
	fields.forEach((field)=>
	{
		if(data[field.getAttribute("id")])
			field.value=data[field.getAttribute("id")];
		else
			field.value="";
	});
	change_btn_text(parent,false);
	if(document.getElementById(parent).querySelector(".btn_remove"))
		document.getElementById(parent).querySelector(".btn_remove").classList.remove("hide");
}

function options_to_ergo(options)
{
	const sel=document.getElementById("ergo_desc");
	sel.innerHTML="";
	options.forEach((e)=>
	{
		let option=new Option(e.ergo_desc,e.ergo_desc);
		option.setAttribute("aria-data",JSON.stringify(e));
		sel.add(option);
	});
	sel.addEventListener('change',function(e)
	{
		const data=JSON.parse(this.options[this.selectedIndex].getAttribute("aria-data"));
		const fields=document.querySelectorAll(".ergo_field");
		fields.forEach((field)=>
		{
			if(data[field.getAttribute("id")])
				field.value=data[field.getAttribute("id")];
			else
				field.value="";
		});
	});
}

function actions_to_table(actions)
{
	fill_table("table_theactions",actions,"theaction",function(data)
	{
		fill_info_from_table("section_actions","action_field","theaction_"+data,data);
	});

}
function checklist_to_value(list)
{
	let values=[];
	list.querySelectorAll(".checkbox_item input:checked").forEach(el=>{
		values.push(el.parentElement.getAttribute("aria-id"));
	});
	return(values);
}
function value_to_checklist(list,value)
{
	const fields=document.querySelectorAll(".checkbox_item");
	fields.forEach(el=>el.querySelector("input").checked=false);
	
	if(!value) return;
	console.log(value);
	for(let a=0;a<value.length;a++)
	{
		const input=list.querySelector(`[aria-id='${value[a]}'] input`);
		if(input)
			input.checked=true;
	}
}

function change_btn_text(parent,add)
{
	if(!document.getElementById(parent).querySelector(".btn_text"))
	{
		if(add)
			document.getElementById(parent).querySelector(".btn_update_fields").classList.add("hide");
		else
			document.getElementById(parent).querySelector(".btn_update_fields").classList.remove("hide");
		return;
	}
	if(add)
	{
		document.getElementById(parent).querySelector(".text_add").classList.remove("hide");
		document.getElementById(parent).querySelector(".text_update").classList.add("hide");
	}else{
		document.getElementById(parent).querySelector(".text_add").classList.add("hide");
		document.getElementById(parent).querySelector(".text_update").classList.remove("hide");
	}
	
}

function empty_fields(parent,field_class)
{
	document.getElementById(parent).setAttribute("aria-row",0);
	const fields=document.querySelectorAll("."+field_class);
	fields.forEach((field)=>
	{
		field.value="";
	});
	change_btn_text(parent,true);
	if(document.getElementById(parent).querySelector(".btn_remove"))
		document.getElementById(parent).querySelector(".btn_remove").classList.add("hide");
	if(document.getElementById(parent).querySelector(".selected"))
		document.getElementById(parent).querySelector(".selected").classList.remove("selected");
}

function empty_all_fields()
{
	empty_fields("tab_pharm_staff","staff_field");
	empty_fields("tab_pharm_services","ps_field");
	empty_fields("tab_pharm_actions","action_field");
	empty_fields("tab_pharm_ergo","ergo_field");
	empty_fields("section_ergometrix","ergometrix_field");
	empty_fields("section_products","prod_field");
	empty_fields("section_actions","action_field");
}

function change_section(type,section,scroll=true)
{
	document.querySelector(type+".visible").classList.remove("visible");
	document.getElementById(section).classList.add("visible");
	if(scroll) window.scrollTo({top: 0,behavior: "smooth"});
}

async function update_fields_data(from)
{
	let params={function:from.getAttribute("aria-function"),data:{}};
	const field_class=from.getAttribute("aria-class");

	const fields=document.querySelectorAll("."+field_class);
	fields.forEach((field)=>
	{
		if(!field.hasAttribute("aria-read-only"))
		{
			if(field.classList.contains("checklist"))
				params["data"][field.getAttribute("id")]=checklist_to_value(field);
			else	
				params["data"][field.getAttribute("id")]=field.value;
		}
	});

	params["row_id"]=document.getElementById(from.getAttribute("aria-parent")).getAttribute("aria-row");
	params["pharm_id"]=document.getElementById("table_pharms").getAttribute("current_id");

	console.log("Update:",params);

	params["session_token"]=page_vars["session_token"];
	const res = await fetch(server_url, 
		{
			method: 'post',
			mode: "cors",
			headers: {
				  'Accept': 'application/json',
				  'Content-Type': 'application/json'
			},
			body: JSON.stringify(params)
		});	

	let json=await res.json();
	if(!json){ alert("error"); return;}
	if(!json.status){alert("error: "+json.error); return};

	console.log("Result: ",json);
	if(json.list)
	{
		show_list(from.getAttribute("aria-table"),json.list);
		// OJO
		if(document.getElementById(from.getAttribute("aria-table")).querySelector("[aria-id='"+params["row_id"]+"']"))
			document.getElementById(from.getAttribute("aria-table")).
				querySelector("[aria-id='"+params["row_id"]+"']").classList.add("selected");
		else
			empty_fields(from.getAttribute("aria-parent"),from.getAttribute("aria-class")); 
		
	}
	
	if(json.ergo)
		options_to_ergo(json.ergo);
	
	if(json.group)
		document.getElementById("btn_view_agreement").setAttribute("aria-group",json.group);
	else
		document.getElementById("btn_view_agreement").removeAttribute("aria-group");

	if(from.getAttribute("post-function"))
	{
		if(json.post_data)
			window[from.getAttribute("post-function")](json.post_data);
	}

	alert("Datos actualizados");
}

async function remove_item(from,parent,table,field,field_class)
{
	let params={function:"removeItem",item_table:table,item_field:field};
	params["row_id"]=document.getElementById(from.getAttribute("aria-parent")).getAttribute("aria-row");
	params["pharm_id"]=document.getElementById("table_pharms").getAttribute("current_id");
	console.log("Remove item:",params);

	params["session_token"]=page_vars["session_token"];
	const res = await fetch(server_url, 
		{
			method: 'post',
			mode: "cors",
			headers: {
				  'Accept': 'application/json',
				  'Content-Type': 'application/json'
			},
			body: JSON.stringify(params)
		});	

	let json=await res.json();
	if(!json){ alert("error"); return;}
	if(!json.status){alert("error: "+json.error); return};

	alert("Item eliminado");

	if(json.list)
	{
		fill_table("table_actions",json.list,"action",function(data)
		{
			fill_info_from_table("tab_pharm_actions","action_field","action_"+data,data);
		});
	}else
		document.getElementById(parent).querySelector(".selected").remove();
	empty_fields(parent,field_class);
	
}

function show_list(table,list)
{
	const tab=document.getElementById(table).getAttribute("aria-tab");
	const field_class=document.getElementById(table).getAttribute("aria-field");
	fill_table(table,list,field_class,function(data)
		{
			fill_info_from_table(tab,field_class+"_field",field_class+"_"+data,data);
		});
}

function load_pharms(e)
{
	if(e)
		e.preventDefault();
	load_list({function:"getPharms",
			sortby:document.getElementById("filter_sort").value,
			filterby:document.getElementById("filter_type").value,
			filter_value:document.getElementById("filter_value").value,
			filter_active:document.getElementById("filter_active").value
		},
			"table_pharms","filter_pharms_count",function(element)
		{
			const pharm_name=document.querySelector("#table_pharms [aria-id='"+element+"'] "+
							"[aria-field='pharm_name']").innerText;
			document.getElementById("filter_pharm_name").innerText=pharm_name;
	
			//empty_all_fields();
			document.getElementById("btn_update_pharm").querySelector(".text_add").classList.add("hide");
			document.getElementById("btn_update_pharm").querySelector(".text_update").classList.remove("hide");

			change_section("section","section_pharm_data",true);
			document.getElementById("table_pharms").setAttribute("current_row","pharm_"+element);
			document.getElementById("table_pharms").setAttribute("current_id",element);
			load_data({function:"getPharmData",pharm:element},"pharm_field");
		});
}

async function load()
{
	show_lng(page_lng);
	document.querySelector(".admin_version").innerHTML=version;

	empty_all_fields();

	load_config();

	document.querySelectorAll(".btn_update_fields").forEach(element=>{
		element.addEventListener('click', function(e)
		{
			e.preventDefault();
			update_fields_data(this);
		},false);
	});

	document.querySelectorAll(".btn_new_fields").forEach(element=>{
		element.addEventListener('click', function(e)
		{
			e.preventDefault();
			empty_fields(this.getAttribute("aria-parent"),this.getAttribute("aria-class"));
		},false);
	});

	document.querySelectorAll(".btn_remove_item").forEach(element=>{
		element.addEventListener('click', function(e)
		{
			e.preventDefault();
			const res=window.prompt("Teclea 'eliminar' para confirmar");
			if(res=='eliminar')
				remove_item(this,
					this.getAttribute("aria-parent"),
					this.getAttribute("aria-table"),
					this.getAttribute("aria-field"),
					this.getAttribute("aria-class"));
			else
				alert("Eliminación cancelada");
		},false);
	});

	document.getElementById("btn_new_pharm").addEventListener('click',function(e)
	{
		e.preventDefault();
		change_section("section","section_pharm_data",true);
		document.getElementById("table_pharms").setAttribute("current_row","");
		document.getElementById("table_pharms").setAttribute("current_id",0);

		document.getElementById("filter_pharm_name").innerText="Nueva";

		empty_fields(this.getAttribute("aria-parent"),this.getAttribute("aria-class"));

		document.querySelector(".tab_link.selected").classList.remove("selected");
		document.querySelector('.tab_link[aria-tab="tab_pharm_data"]').classList.add("selected");
		change_section(".tab_pharm",this.getAttribute("aria-tab"));

		document.getElementById("btn_update_pharm").querySelector(".text_update").classList.add("hide");
		document.getElementById("btn_update_pharm").querySelector(".text_add").classList.remove("hide");
	});

	document.querySelectorAll(".nav_link").forEach(element=>{
		element.addEventListener('click', function(e)
		{
			e.preventDefault();
			document.querySelector(".nav_link.selected").classList.remove("selected");
			element.classList.add("selected");
			change_section("section",element.getAttribute("aria-section"));
		},false);
	});
	document.querySelectorAll(".tab_link").forEach(element=>{
		element.addEventListener('click', function(e)
		{
			e.preventDefault();
			document.querySelector(".tab_link.selected").classList.remove("selected");
			element.classList.add("selected");
			change_section(".tab_pharm",element.getAttribute("aria-tab"));
		},false);
	});

	document.getElementById("btn_filter_pharms").addEventListener('click',load_pharms);

	load_pharms(null);

	document.querySelectorAll("[aria-btn-trigger]").forEach(element=>{
		element.addEventListener('keypress', function(e)
		{
			if(e.key==="Enter")
			{
				document.getElementById(this.getAttribute("aria-btn-trigger")).dispatchEvent(new Event('click'));
			}
		},false);
	});
	document.querySelectorAll("[aria-sel-trigger]").forEach(element=>{
		element.addEventListener('change', function(e)
		{
				document.getElementById(this.getAttribute("aria-sel-trigger")).dispatchEvent(new Event('click'));
		},false);
	});

	// loading pharms 
	/*load_list({function:"getPharms"},"table_pharms","filter_pharms_count",function(element)
	{
		const pharm_name=document.querySelector("#table_pharms [aria-id='"+element+"'] "+
						"[aria-field='pharm_name']").innerText;
		document.getElementById("filter_pharm_name").innerText=pharm_name;

		//empty_all_fields();

		change_section("section","section_pharm_data",true);
		document.getElementById("table_pharms").setAttribute("current_row","pharm_"+element);
		document.getElementById("table_pharms").setAttribute("current_id",element);
		load_data({function:"getPharmData",pharm:element},"pharm_field");
	});
	*/

	document.getElementById("btn_return_pharms").addEventListener('click', function(e)
	{
		e.preventDefault();
		empty_all_fields();
		change_section("section","section_pharms",true);
	});

	document.querySelectorAll(".btn_pharms_move").forEach(btn=>{
		btn.addEventListener('click', function(e)
		{
			e.preventDefault();

			empty_all_fields();

			const current=document.getElementById("table_pharms").getAttribute("current_row");
			if(!current) return;
			let next=null;
			if(btn.getAttribute("href")=="next")
				next=document.getElementById(current).nextSibling;
			else
				next=document.getElementById(current).previousSibling;
			
			if(next)
			{
				const element=next.getAttribute("aria-id");
				const pharm_name=document.querySelector("#table_pharms [aria-id='"+element+"'] "+
						"[aria-field='pharm_name']").innerText;
				document.getElementById("filter_pharm_name").innerText=pharm_name;
				document.getElementById("table_pharms").setAttribute("current_row","pharm_"+element);
				document.getElementById("table_pharms").setAttribute("current_id",element);
				load_data({function:"getPharmData",pharm:element},"pharm_field");
			}
		});
	});

	document.getElementById("btn_view_agreement").addEventListener("click",e=>{
		e.preventDefault();
		const ag_date=document.getElementById("ag_date").value;
		if(!ag_date)
		{
			alert("El contrato no está aceptado ni firmado");
			return;
		}
		const popup=document.getElementById("agreement_popup");

		let doc="agreement_doc";
		if(e.target.hasAttribute("aria-group"))
			doc+="_"+e.target.getAttribute("aria-group");

		popup.querySelector("iframe").setAttribute("src",
			document.getElementById(doc).getAttribute("href"));

		popup.classList.remove("hide");
	});

	window.addEventListener('message', async function (e) {
		if(e.data==null) return;

		if(e.data=="farmaval_close_agreement")
		{
			document.getElementById("agreement_popup").classList.add("hide");			
			return;
		}
		if(e.data=="farmaval_agreement_data")
		{
			const form="tab_pharm_agreement"; 
			
			const ag_date=document.getElementById("ag_date").value;
			const now=new Date(ag_date);
			const data={
				ag_date_day:now.getDate(),
				ag_date_month_es:document.getElementById("agreement_months_es").innerHTML.split(",")[now.getMonth()],
				ag_date_month_ca:document.getElementById("agreement_months_ca").innerHTML.split(",")[now.getMonth()],
				ag_date_year:now.getFullYear(),

				ag_date:now.toLocaleDateString('es-ES', {
				year: 'numeric',
				month: 'long',
				day: 'numeric'
			  })};
			document.getElementById(form).querySelectorAll(".ag_field").forEach(el=>{

				if(el.tagName==="IMG")
				{
					data[el.getAttribute("id")]=el.getAttribute("src");
				}else{
					if(el.value && el.value!=="")
					{
						if(el.tagName==="SELECT")
							data[el.getAttribute("id")]=el.options[el.selectedIndex].text
						else
							data[el.getAttribute("id")]=el.value;
					}
				}
			});

			e.source.postMessage({msg:"farmaval_agreement_data",data:data});

			return;
		}
	});


}

function pharm_added(data)
{
	//const pharm_name=document.querySelector("#table_pharms [aria-id='"+element+"'] "+
	//"[aria-field='pharm_name']").innerText;
	document.getElementById("filter_pharm_name").innerText=data.pharm_name;

	//empty_all_fields();
	document.getElementById("btn_update_pharm").querySelector(".text_add").classList.add("hide");
	document.getElementById("btn_update_pharm").querySelector(".text_update").classList.remove("hide");

	//change_section("section","section_pharm_data",true);
	document.getElementById("table_pharms").setAttribute("current_row","pharm_"+data.pharm_id);
	document.getElementById("table_pharms").setAttribute("current_id",data.pharm_id);
	load_data({function:"getPharmData",pharm:data.pharm_id},"pharm_field");
}

function img_to_data(imgElement) {
	// Crear un canvas
	const canvas = document.createElement('canvas');
	const contexto = canvas.getContext('2d');

	// Establecer el tamaño del canvas igual al tamaño de la imagen
	canvas.width = imgElement.width;
	canvas.height = imgElement.height;

	// Dibujar la imagen en el canvas
	contexto.drawImage(imgElement, 0, 0);

	// Obtener la imagen en formato base64
	const dataURL = canvas.toDataURL('image/png'); // Puedes cambiar a 'image/jpeg' si es necesario
	return dataURL;
}

document.addEventListener("DOMContentLoaded", load, false);

